import React, { FC } from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { WindowLocation } from "@reach/router";
import styled from "styled-components";

import Layout from "../layout/layout";
import { isAuthenticated, login } from "../utils/auth";
import { MetaData } from "../layout/metadata";
import { ArticleFooter } from "../layout/articles/article-footer";
import { PlaceholderMessage } from "../components/PlaceholderMessage";
import { ArticleData } from "../layout/articles/ArticleData";
import { Image } from "../components";

const headerHeight = "250px";
const headerOverflowAmount = "0.5rem";

const Article = styled.article`
  display: block;
  margin: calc(2rem + ${headerOverflowAmount}) 0 4rem;
`;

const ArticleHeader = styled.header`
  position: relative;
  display: block;
  margin-bottom: calc(2rem + ${headerOverflowAmount});
`;

const HeaderImageContainer = styled.div`
  position: absolute;
  top: -${headerOverflowAmount};
  bottom: -${headerOverflowAmount};
  left: calc(-2 * ${headerOverflowAmount});
  right: calc(-2 * ${headerOverflowAmount});

  > .gatsby-image-wrapper {
    height: 100%;
  }
`;

const HeaderTextContainer = styled.div`
  position: relative;
`;

const HeaderTextMarginWidener = styled.div`
  background: rgba(255, 255, 255, 0.8);
  padding: ${headerOverflowAmount};
  margin: 0 -${headerOverflowAmount};
`;

const ArticleTitle = styled.h1`
  font-size: 1.7rem;
`;

interface BasicPostData {
  fields: { slug: string };
  frontmatter: { title: string };
}

interface Props {
  data: {
    mdx: ArticleData & { body: string };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  pageContext: {
    previous?: BasicPostData;
    next?: BasicPostData;
  };
  location: WindowLocation;
}

const BlogPostTemplate: FC<Props> = ({ data, pageContext, location }) => {
  if (!isAuthenticated()) {
    login(location.pathname);
    return <PlaceholderMessage>Authenticating...</PlaceholderMessage>;
  }

  const post = data.mdx;
  const { previous, next } = pageContext;

  return (
    <Layout>
      <MetaData
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <Article>
        <ArticleHeader className="article-header">
          <HeaderImageContainer>
            <Image assetSrc={post.frontmatter.featuredImage} />
          </HeaderImageContainer>
          <HeaderTextContainer>
            <HeaderTextMarginWidener>
              <div>{post.frontmatter.date}</div>
              <ArticleTitle>{post.frontmatter.title}</ArticleTitle>
              <p>{post.frontmatter.description}</p>
            </HeaderTextMarginWidener>
          </HeaderTextContainer>
        </ArticleHeader>

        <div className="article-content">
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
      </Article>

      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      wordCount {
        words
      }
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        featuredImage
      }
    }
  }
`;
